<template>
    <div class="container" id="CompetitionResults">
        <el-page-header @back="$router.back()">
            <div slot="content">
                <span>{{subLeagueName}}-比赛名次和证书</span>
                <el-button 
                    type="text"
                    style="margin-left:15px"
                    v-href="`/Match/ScoreRegistration/${subLeagueId}?name=${subLeagueName}`" 
                    v-if="type == 1"
                >按报名表录入成绩</el-button>
                <el-button 
                    type="text"
                    style="margin-left:15px"
                    v-href="`/Match/ImportResults/${subLeagueId}?name=${subLeagueName}`" 
                    v-if="type == 1"
                >上传成绩册</el-button>
            </div>
        </el-page-header>
        <div class="CompetitionResults_content">
            <el-descriptions title="筛选条件"></el-descriptions>
            <el-form :model="form" label-width="100px" size="small" class="form">
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="代表机构：">
                            <el-input v-model.trim="form.companyName" placeholder="机构全称或简称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="代表队：">
                            <el-input v-model.trim="form.deputationName" placeholder="代表队名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="运动员：">
                            <el-input v-model.trim="form.athleteName" placeholder="运动员姓名"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="组别：">
                            <el-select v-model="form.groupId" placeholder="请选择">
                                <el-option label="不限" :value="0"></el-option>
                                <el-option v-for="(item,index) in groups" :key="index"
                                    :label="item.GroupName" 
                                    :value="item.GroupId"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="名次：">
                            第<el-input v-model.number="form.rankStart" type="number" style="width:80px"></el-input>名
                            ~
                            第<el-input v-model.number="form.rankEnd" type="number" style="width:80px"></el-input>名
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="排序：">
                            <el-select v-model="form.orderType" placeholder="请选择">
                                <el-option v-for="(item,index) in sorts" :key="index"
                                    :label="item.text" 
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-button 
                            type="primary" size="small" 
                            style="margin-left:50px;width:100px"
                            @click="query()"
                        >查询</el-button>
                    </el-col>
                </el-row>
            </el-form>
            <div class="statistics" v-if="isGet">
                <p v-if="AllCount == 0">没有满足条件的记录</p>
                <p v-else>共有 {{AllCount}} 条记录满足条件，{{(list.length == AllCount) ? '已全部加载' : `已加载前 ${list.length} 条记录`}}。</p>
                <div v-show="AllCount > 0">
                    <el-button 
                        type="danger" size="small"
                        @click="deleteRecord()"
                        v-if="type == 1"
                    >删除所有记录</el-button>
                    <!-- <el-button 
                        type="success" size="small"
                        @click="exportResults()"
                    >导出</el-button> -->
                </div>
            </div>
            <el-row>
                <el-table
                    v-loading="loading"
                    element-loading-text="数据加载中"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)"
                    :data="list"
                    border
                    size="mini"
                    max-height="500px"
                >   
                    <el-table-column label="序号" width="50" type="index" align="center"></el-table-column>
                    <el-table-column prop="EventsName" label="比赛项目" max-width="120"></el-table-column>
                    <el-table-column prop="GroupName" label="组别"></el-table-column>
                    <el-table-column prop="Names" label="参赛者"></el-table-column>
                    <el-table-column prop="DeputationName" label="代表队"></el-table-column>
                    <el-table-column prop="CompanyName" label="代表机构"></el-table-column>
                    <el-table-column prop="ComanyMemberNo" label="机构会员号" max-width="120"></el-table-column>
                    <el-table-column prop="CertificateNo" label="证件号码" max-width="120"></el-table-column>
                    <el-table-column prop="Phone" label="手机号码" max-width="120"></el-table-column>
                    <el-table-column prop="MemberNo" label="个人会员号" max-width="120"></el-table-column>
                    <el-table-column :width="type == 1 ? '100' : ''" :render-header="renderHeader">
                        <template slot-scope="scope">
                            <el-input v-if="isUpdateRanking" v-model.number="rankings[scope.row.ScoreOrderId]"  type="number" style="width:100%;" size="small"></el-input>
                            <span v-else>{{scope.row.Ranking}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="获奖证书" min-width="90">
                        <template slot-scope="scope">
                             <el-button 
                                size="mini"
                                type="primary"
                                @click="lookCertificate(scope.row.CertificateFile)"
                                v-if="scope.row.CertificateFile"
                            >查看</el-button>
                            <el-upload
                                class="upload-demo"
                                action=""
                                :auto-upload="false"
                                :show-file-list="false"
                                accept="image/*"
                                :on-change="(file)=>{uploadCertificate(file, scope.$index, scope.row.ScoreOrderId)}">
                                <el-button 
                                    size="mini"
                                    type="success"
                                >上传</el-button>
                            </el-upload>
                             <el-button 
                                size="mini"
                                type="danger"
                                @click="deleteCertificate(scope.$index, scope.row.ScoreOrderId)"
                                v-if="scope.row.CertificateFile"
                            >删除</el-button>
                        </template>
                    </el-table-column>
                    <div slot="append" class="load_more" v-show="list.length < AllCount" @click="getList()">加载下一页</div>
                </el-table>
            </el-row>
        </div>       
        <el-image ref="imagePreview" class="image_preview"
            src="~@/assets/images/logo.png" 
            :preview-src-list="imagePreviews">
        </el-image>
    </div>
</template>
<script>
import {
    GetLeagueEventsGroup,
    GetScoreOrderList,
    ExportScoreOrderNameList,
    AlterScoreOrder,
    DelScoreOrders,
    GetScoreOrderUploadOptions,
    SaveScoreOrderCertificateFile
} from '@/api'
import OSS from 'ali-oss'
import { nanoid } from "nanoid"
import * as XLSX from 'xlsx/xlsx.mjs';
export default {
    data(){
        var id = parseInt(this.$route.params.subLeagueId),
            type = parseInt(this.$route.params.type);
        return {
            type: isNaN(type) ? 0 : type,//0：查看 1：修改
            subLeagueId: isNaN(id) ? 0 : id,
            subLeagueName: this.$route.query.name,
            form:{
                groupId: 0,
                companyName: "",
                deputationName: "",
                athleteName: "",
                rankStart: null,
                rankEnd: null,
                orderType: 1
            },
            dataForm: {},
            groups: [],
            sorts: [
                {text: "按名次，从高到低", id: 1},
                {text: "按名次，从低到高", id: 2},
                {text: "按参赛者名称", id: 3},
            ],
            AllCount: 0,
            list: [],
            loading:false,
            isGet: false,
            isUpdateRanking: false,
            rankings: {},
            uploadLoading: null,
            UploaderOptions: {},
            imagePreviews: []
        }
    },
    created(){
        GetLeagueEventsGroup({
            SubLeagueId: this.subLeagueId
        }).then(data=>{
            if(data.Code == 0){
                this.groups = data.GroupList;
            }
        })
        GetScoreOrderUploadOptions({
            SubLeagueId: this.subLeagueId
        }).then(data=>{
            if(data.Code == 0){
                this.UploaderOptions = data.UploadOption;
                console.log(this.UploaderOptions);
            }
        })
        this.getList();
    },
    watch:{
        list:{
            deep: true,
            immediate: true,
            handler(arr){
                if(arr.length > 0){
                    let rankingObj = {};
                    for(var i=0;i<arr.length;i++){
                        let obj = arr[i];
                        rankingObj[obj.ScoreOrderId] = obj.Ranking;
                    }
                    this.$set(this,'rankings',rankingObj);
                }
            }
        }
    },
    methods:{
        //获取数据
        query(){
            this.lastId = 0;
            this.getList();
        },
        getList(){
            var form = this.form;
            this.loading = true;
            var lastId = this.lastId;
            if(lastId == 0){
                this.AllCount = 0;
                this.list = [];
                this.rankings = {};
            }
            GetScoreOrderList({
                SubLeagueId: this.subLeagueId,
                GroupId: form.groupId,
                CompanyName: form.companyName,
                DeputationName: form.deputationName,
                AthleteName: form.athleteName,
                RankStart: form.rankStart || null,
                RankEnd: form.rankEnd || null,
                OrderType: form.orderType,
                Continuation: {
                    LastId: lastId,
                    MaxCount: 20
                },
            }).then((data)=>{
                this.loading = false;
                if(data.Code == 0){
                    if(!this.isGet){
                        this.isGet = true;
                    }
                    this.AllCount = data.AllCount;
                    var ContinueList = data.ContinueList || [];
                    if(ContinueList.length == 0){
                    }else{
                        this.lastId = ContinueList[ContinueList.length - 1].ScoreOrderId;
                        this.$set(this,'list',[...this.list,...ContinueList]);
                    }
                }
            }).catch(() => {
                this.loading = false;
            })
        },
        //删除成绩
        deleteRecord(){
            this.$confirm('将从成绩表中删除满足筛选条件的所有记录，请确认').then(() => {
                var form = this.form;
                DelScoreOrders({
                    SubLeagueId: this.subLeagueId,
                    GroupId: form.groupId,
                    CompanyName: form.companyName,
                    DeputationName: form.deputationName,
                    AthleteName: form.athleteName,
                    RankStart: form.rankStart || null,
                    RankEnd: form.rankEnd || null,
                }).then(data=>{
                    if(data.Code == 0){
                        this.$message.success('删除成功');
                        this.query();
                    }
                })
            })
        },
        //导出成绩
        exportResults(){
                var form = this.form;
            let loading = this.$loading({
                    lock: true,
                    text: "正在导出",
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
            ExportScoreOrderNameList({
                SubLeagueId: this.subLeagueId,
                GroupId: form.groupId,
                CompanyName: form.companyName,
                DeputationName: form.deputationName,
                AthleteName: form.athleteName,
                RankStart: form.rankStart || null,
                RankEnd: form.rankEnd || null,
                OrderType: form.orderType
            }).then(data=>{
                loading.close();
                if(data.Code == 0){
                    var ExcelSheet = XLSX.utils.aoa_to_sheet(this.toArr(data.ExcelBase64));
                        ExcelSheet['!cols'] = [
                            {wpx: 80},
                            {wpx: 80},
                            {wpx: 100},
                            {wpx: 140},
                            {wpx: 140},
                            {wpx: 100},
                            {wpx: 240},
                            {wpx: 160},
                            {wpx: 140},
                            {wpx: 50},
                        ];
                    var blob = this.sheet2blob([ExcelSheet]);
                    this.openDownloadXLSXDialog(blob, '成绩册.xlsx');
                }
            }).catch(()=>{
                loading.close();
            })
        },
        toArr(dataBase64){
            var data = new Buffer(dataBase64, 'base64').toString('utf8');
            console.log(data);
            var arr = data.split(/\n/),
                arr2 = [];
            for(var i = 0;i<arr.length;i++){
                if(arr[i]){
                    arr2.push(arr[i].split(',').map(n=>JSON.parse(n)));
                }
            }
            return arr2;
        },
         //导出excel相关函数
        sheet2blob(sheets = [], sheetNames = []) {
            var workbook = {
                SheetNames: [],
                Sheets: {}
            };
            for(var i=0;i<sheets.length;i++){
                let sheetName = sheetNames[i] || ('sheet' + (i + 1));
                workbook.Sheets[sheetName] = sheets[i];
                workbook.SheetNames.push(sheetName);
            }
            // 生成excel的配置项
            var wopts = {
                bookType: 'xlsx', // 要生成的文件类型
                bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
                type: 'binary'
            };
            var wbout = XLSX.write(workbook, wopts);
            var blob = new Blob([s2ab(wbout)], {type:"application/octet-stream"});
            // 字符串转ArrayBuffer
            function s2ab(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }
            return blob;
        },
        openDownloadXLSXDialog(url, saveName){
            if(typeof url == 'object' && url instanceof Blob){
                url = URL.createObjectURL(url); // 创建blob地址
            }
            var aLink = document.createElement('a');
            aLink.href = url;
            aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
            var event;
            if(window.MouseEvent) event = new MouseEvent('click');
            else{
                event = document.createEvent('MouseEvents');
                event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            }
            aLink.dispatchEvent(event);
        },
        //名次修改按钮显示
        renderHeader(h){
            var button = null;
            if(this.type == 1 && this.AllCount > 0){
                if(this.isUpdateRanking){
                    button = h(
                        'el-button',
                        {
                            props:{
                                type: 'success',
                                size: 'mini'
                            },
                            style: 'margin-left:8px;padding:4px 10px;',
                            on:{
                                click: this.saveRanking
                            }
                        },
                        '保存'
                    )
                }else{
                    button = h(
                        'el-button',
                        {
                            props:{
                                type: 'info',
                                size: 'mini'
                            },
                            style: 'margin-left:8px;padding:4px 10px;',
                            on:{
                                click: ()=>{this.isUpdateRanking = true}
                            }
                        },
                        '修改'
                    )
                }
            }
            return [h(
                'span',
                '名次'
            ),button]
        },
        //保存名次
        saveRanking(){
            var rankings = this.rankings,
                rankList = [];
            for(var key in rankings){
                let value = rankings[key],
                    valueNum = parseInt(rankings[key]);
                if(value && !isNaN(valueNum) && valueNum > 0){
                    rankList.push({
                        ScoreOrderId: parseInt(key),
                        Ranking: valueNum
                    })
                }else{
                    rankList.push({
                        ScoreOrderId: key,
                        Ranking: 0
                    })
                }
            }
            const loading = this.$loading({
                lock: true,
                text: '正在保存成绩...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            AlterScoreOrder({
                "SubLeagueId": this.subLeagueId,
                "RankList": rankList
            }).then(data=>{
                loading.close();
                if(data.Code == 0){
                    this.$message.success('成绩保存成功');
                    this.isUpdateRanking = false;
                    this.query();
                }
            }).catch(err=>{
                loading.close();
            })
        },
        //查看获奖证书
        lookCertificate(url){
            this.imagePreviews = [url];
            this.$refs['imagePreview'].showViewer = true;
        },
        //上传获奖证书
        uploadCertificate(file, index, scoreOrderId){
            const types = file.name.split('.')[1];
            const fileType = ['jpg', 'jpeg', 'png'].some(item => item === types);
            if (!fileType) {
                this.$message.error('格式错误！请重新上传');
                return
            }
            this.uploadLoading = this.$loading({
                lock: true,
                text: '正在上传...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.ossUpload(file, index, scoreOrderId);
        },
        ossUpload(fileData, index, scoreOrderId){
            const _this = this;
            const options = this.UploaderOptions;
            const client = new OSS({
                // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
                region: options.Region,
                // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeyId: options.AccessKeyId,
                accessKeySecret: options.AccessKeySecret,
                // 从STS服务获取的安全令牌（SecurityToken）。
                stsToken: options.SecurityToken,
                // 填写Bucket名称。
                bucket: options.Bucket
            });
            const headers = {
                'Cache-Control': 'public',
                'Content-Disposition': fileData.name,
            }
            let nameID = options.ObjectNamePrefix + nanoid();
            client.put(nameID, fileData.raw, headers).then(function (r1) {
                let CertificateFile = r1.url;
                SaveScoreOrderCertificateFile({
                    SubLeagueId: _this.subLeagueId,
                    ScoreOrderId: scoreOrderId,
                    CertificateFile
                }).then(data=>{
                    _this.uploadLoading.close();
                    if(data.Code == 0){
                        _this.$message.success('上传成功');
                        _this.$set(_this.list[index], 'CertificateFile', CertificateFile);
                    }
                }).catch(err=>{
                    _this.uploadLoading.close();
                })
                console.log('put success:');
            }).catch(function (err) {
                _this.uploadLoading.close();
                _this.$message.error('上传失败');
                console.log('error: '+JSON.stringify(err));
            });
        },
        //删除获奖证书
        deleteCertificate(index, scoreOrderId){
            this.$confirm('将删除该参赛者的获奖证书，请确认').then(() => {
                SaveScoreOrderCertificateFile({
                    SubLeagueId: this.subLeagueId,
                    ScoreOrderId: scoreOrderId,
                    CertificateFile: null
                }).then(data=>{
                    if(data.Code == 0){
                        this.$message.success('获奖证书删除成功');
                        this.$set(this.list[index], 'CertificateFile', null);
                    }
                })
            })
        }
    }
}
</script>
<style scoped>
.CompetitionResults_content .el-form-item{
    margin-bottom: 0px;
}
.CompetitionResults_content .load_more{
    font-size: 16px;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
}
.CompetitionResults_content .load_more:hover{
    color: #409EFF;
}
.image_preview{
    display: none;
}
</style>